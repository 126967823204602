import React from "react"

export default props => (
  <>
    <h4>Font</h4>
    <ul className="billboard-fonts">
      {props.fonts.map((font, i) => (
        <li
          style={{ fontFamily: font.font }}
          key={i}
          onClick={() => {
            props.setFont(font.font)
          }}
        >
          <span>{props.label}</span>
        </li>
      ))}
    </ul>
  </>
)
