import React from "react"

export default props => (
  <>
    <h3>Label</h3>
    <label className="billboard-label">
      <span>Label</span>
      <textarea value={props.label} onChange={props.setLabel} />
    </label>
    <label className="billboard-font-size">
      <span>Font size: {props.fontSize}</span>
      <input
        type="range"
        value={props.fontSize}
        min="20"
        max="600"
        onChange={props.setFontSize}
      />
    </label>
  </>
)
