import React from "react"

export default props => (
  <>
    <h3>Background</h3>
    <ul className="billboard-background">
      {props.backgrounds.map(image => (
        <li key={image.id}>
          <img
            src={image.preview}
            alt={image.title}
            onClick={e => props.setBackground(image)}
          />
        </li>
      ))}
      <li key="custom">
        <label>
          Add custom background
          <input type="file" onChange={props.customBackground} />
        </label>
      </li>
    </ul>
  </>
)
