import React, { Component, Fragment } from "react"
import { graphql } from "gatsby"
import slug from "slug"

/*import { SocialLogins } from "gatsby-theme-firebase"*/
/*import { useAuth } from "gatsby-theme-firebase"*/

import Layout from "../components/layout"
import SEO from "../components/seo"
import Background from "../components/billboard/Background"
import Dimensions from "../components/billboard/Dimensions"
import Font from "../components/billboard/Font"
import Label from "../components/billboard/Label"
import drawImageProp from "../utils/drawImageProp"

require("typeface-nova-cut")
require("typeface-im-fell-french-canon-sc")
require("typeface-staatliches")
require("typeface-yellowtail")

class BillboardGenerator extends Component {
  state = {
    label: "",
    width: 1890,
    height: 945,
    fontSize: 160,
    image: {},
    backgrounds: [],
    dimensions: [
      { width: 1890, height: 945, label: "16cm × 8cm" },
      { width: 590, height: 590, label: "5cm × 5cm" },
      { width: 118, height: 118, label: "1cm × 1cm" },
    ],
    font: "Nova Cut",
    fonts: [
      { font: "Nova Cut", label: "Nova Cut" },
      { font: "IM Fell French Canon SC", label: "IM Fell French Canon SC" },
      { font: "Staatliches", label: "Staatliches" },
      { font: "Yellowtail", label: "Yellowtail" },
    ],
  }

  image = undefined
  ctx = undefined
  link = undefined

  componentDidMount() {
    this.image = new Image()
    this.ctx = this.refs.canvas.getContext("2d")
    if (typeof window !== "undefined" && window.document) {
      this.link = window.document.createElement("a")
    }
    let backgrounds = []
    this.props.data.allContentfulGallery.nodes.forEach(node =>
      node.content.forEach(content => {
        backgrounds.push({
          id: content.id,
          preview: content.fluid.src,
          image: content.fixed.src,
          title: content.title,
        })
      })
    )
    this.image.onload = () => {
      this.updateCanvas()
    }
    this.setState(
      {
        label: localStorage.getItem("background-generator-label") || "Label",
        backgrounds,
      },
      this.setBackground(backgrounds[0] || {})
    )
  }
  updateCanvas() {
    const canvas = this.refs.canvas
    this.ctx.clearRect(0, 0, canvas.width, canvas.height)

    this.ctx.fillStyle = "black"
    this.ctx.fillRect(0, 0, canvas.width, canvas.height)

    drawImageProp(
      this.ctx,
      this.image,
      0,
      0,
      this.refs.canvas.width,
      this.refs.canvas.height
    )
    this.ctx.fillStyle = "white"
    this.ctx.font = `${this.state.fontSize}px "${this.state.font}"`

    const lines = this.state.label.split(/\r?\n/)

    lines.forEach((line, i) => {
      this.ctx.fillText(
        line,
        this.state.width / 10,
        (this.state.height + this.state.fontSize * lines.length) / 2 -
          this.state.fontSize * (lines.length - 1) +
          this.state.fontSize * i
      )
    })
  }
  setLabel = e => {
    localStorage.setItem("background-generator-label", e.target.value)
    this.setState({ label: e.target.value }, this.updateCanvas)
  }
  setFontSize = e => {
    this.setState(
      { fontSize: parseInt(e.target.value, 10) },
      this.updateCanvas()
    )
  }
  setDimensions = (width, height) => {
    this.setState({ width, height }, this.updateCanvas)
  }
  setFont = font => {
    this.setState({ font }, this.updateCanvas)
  }
  customBackground = e => {
    const reader = new FileReader()

    reader.onload = () => {
      const img = reader.result
      const image = { id: "id", preview: img, image: img, title: "title" }
      let backgrounds = [...this.state.backgrounds]
      backgrounds.push(image)
      this.setState({ backgrounds })
      this.setBackground(image)
    }

    reader.readAsDataURL(e.target.files[0])
  }
  setBackground = image => {
    if (image) {
      this.setState({ image }, () => {
        this.image.crossOrigin = "anonymous"
        this.image.src = this.state.image.image
      })
    } else {
      this.updateCanvas()
    }
  }
  download = () => {
    const canvas = this.refs.canvas

    this.link.href = canvas.toDataURL("image/png;base64")
    this.link.download = `billboard_layerized.com_${slug(this.state.label)}.png`
    this.link.click()
  }
  render() {
    return (
      <div className="billboard-generator">
        <div className="billboard-head">
          <div className="billboard-intro">
            <p>
              Create a custom billbaord by selecting background image, font,
              font size. Be sure to provide a snappy text to go along with the
              rest of your design.
            </p>
            <p>
              You can download your design in preset sizes fitting common
              designs.
            </p>
          </div>
          <div className="billboard-download">
            <button onClick={this.download}>Download design</button>
          </div>
        </div>
        <div className="billboard-canvas">
          <canvas
            ref="canvas"
            width={this.state.width}
            height={this.state.height}
          ></canvas>
        </div>
        <h2>Settings</h2>
        <Label
          label={this.state.label}
          setLabel={this.setLabel}
          fontSize={this.state.fontSize}
          setFontSize={this.setFontSize}
        />

        <Font
          fonts={this.state.fonts}
          label={this.state.label}
          setFont={this.setFont}
        />

        <Background
          backgrounds={this.state.backgrounds}
          setBackground={this.setBackground}
          customBackground={this.setCustomBackground}
        />

        <Dimensions
          dimensions={this.state.dimensions}
          setDimensions={this.setDimensions}
          image={this.state.image}
        />
      </div>
    )
  }
}

const BillboardGeneratorPage = props => {
  /*const { isLoading, isLoggedIn, profile } = useAuth()*/
  return (
    <Layout>
      <Fragment>
        <SEO
          title="Billboard Generator"
          description="Generate all kinds of billboard posters"
          keywords={[]}
        />
        <h1>Billboard Generator</h1>
        <BillboardGenerator data={props.data} />
        {/*<SocialLogins />
        {isLoading && isLoggedIn}*/}
      </Fragment>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    allContentfulGallery(filter: { title: { eq: "billboards" } }) {
      nodes {
        content {
          id
          fixed(width: 2000, quality: 90) {
            src
          }
          fluid(maxWidth: 200, quality: 90) {
            src
          }
          title
        }
      }
    }
  }
`

export default BillboardGeneratorPage
