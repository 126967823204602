import React from "react"

export default props => (
  <>
    <h3>Dimensions</h3>
    <ul className="billboard-dimensions">
      {props.dimensions.map((dimension, i) => (
        <li
          key={i}
          onClick={() => {
            props.setDimensions(dimension.width, dimension.height)
          }}
        >
          <span
            className="billboard-dimension-template"
            style={{
              backgroundImage: `url(${props.image.preview})`,
              width: dimension.width,
              paddingBottom: `${(dimension.height / dimension.width) * 100}%`,
            }}
          ></span>
          <span>{dimension.label}</span>
        </li>
      ))}
    </ul>
  </>
)
